import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
    asyncCreateVote,
    asyncFetchElection,
} from 'store/reducers/elections/thunks';
import { Outlet, useParams } from 'react-router-dom';
import ActionCard from 'components/elections/ActionCard';
import { IElection } from 'store/reducers/elections/types';
import Chip from 'components/ui/Chip';
import dayjs from 'dayjs';
import './ElectionOutlet.scss';

import { ReactComponent as AlertIcon } from 'assets/icons/mingcute_alert-octagon-fill.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/mingcute_calendar-fill.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/mingcute_time-fill.svg';
import VoteModal from 'forms/modals/VoteModal';
import { QuestionProps } from 'components/ui/Question/Question';
import QuestionBlock from 'containers/QuestionBlock';
import CompletedVoteModal from 'forms/modals/CompletedVoteModal';
import { clearElection } from 'store/reducers/elections';
import { isStudent } from 'js/utils';

const questions: QuestionProps[] = [
    {
        header: 'Я столкнулся с проблемой, кому писать?',
        text: (
            <>
                Если вы столкнулись с проблемой в работе системы online-выборов,
                мы постараемся оперативно её решить!
                <br />
                Просто напишите в{' '}
                <a
                    href="https://t.me/dobroplat"
                    target="_blank"
                    rel="noreferrer"
                >
                    Telegram
                </a>{' '}
                или{' '}
                <a
                    href="https://vk.com/vladhasnoid"
                    target="_blank"
                    rel="noreferrer"
                >
                    VK
                </a>
            </>
        ),
    },
    {
        header: 'Кто эти люди?',
        text: (
            <>
                На этой странице представлены кандидаты на пост председателей
                студенческих советов нашего университета. <br />
                Каждый кандидат — обычный студент СПбГУТ, желающий изменить
                студенческую жизнь в университете в лучшую сторону.
            </>
        ),
    },
    {
        header: 'Где можно посмотреть статистику голосования?',
        text: (
            <>
                Статистику голосования в реальном времени можно узнать в
                аудитории Студенческого совета СПбГУТ 174/2 с 9:00 до 18:00.
            </>
        ),
    },
    {
        header: 'Когда будут оглашены результаты выборов?',
        text: (
            <>
                Голосование завершится 27 сентября в 18:00 по Московскому
                времени. Поскольку распределение голосов подсчитывается системой
                в реальном времени, результаты выборов будут объявлены сразу же
                после завершения голосования и будут опубликованы в группе{' '}
                <a
                    href="https://vk.com/studsovet.bonch"
                    target="_blank"
                    rel="noreferrer"
                >
                    Студенческого совета СПбГУТ
                </a>{' '}
                и{' '}
                <a href="https://vk.com/ingut" target="_blank" rel="noreferrer">
                    InGut
                </a>
                .
            </>
        ),
    },
    {
        header: 'Будет ли очное оглашение результатов выборов?',
        text: (
            <>
                Несомненно. Для всех желающих в 18:00 в аудитории 174/2 в
                реальном времени будут оглашены результаты выборов и объявлен
                победитель.
            </>
        ),
    },
];

function ElectionChips({
    is_active,
    date_end,
    date_start,
}: IElection): React.ReactElement[] {
    return [
        <Chip key={1} icon={<AlertIcon />}>
            Голосование {!is_active && 'еще не '}
            началось
        </Chip>,
        <Chip key={2} icon={<CalendarIcon />}>
            {dayjs(date_start).format('DD MMMM')}
        </Chip>,
        <Chip key={3} icon={<ClockIcon />}>
            с {dayjs(date_start).format('HH:mm ')}
            до {dayjs(date_end).format('HH:mm')}
        </Chip>,
    ];
}

function ElectionOutlet(): React.ReactElement {
    const dispatch = useAppDispatch();

    const { electionId } = useParams();
    const [voteModalOpen, setVoteModalOpen] = React.useState(false);
    const [completedModalOpen, setCompletedModalOpen] = React.useState(false);

    const { user } = useAppSelector(state => state.userStore);

    React.useEffect(() => {
        dispatch(asyncFetchElection(Number(electionId)));
        return () => {
            dispatch(clearElection());
        };
    }, [dispatch, electionId, user]);

    const { election } = useAppSelector(state => state.electionsStore);

    const submitVote = React.useCallback(
        async (candidateId: number) => {
            if (!election) return;
            await dispatch(
                asyncCreateVote({
                    candidate: candidateId || undefined,
                    election: election.id,
                })
            )
                .unwrap()
                .then(() => {
                    dispatch(asyncFetchElection(election.id))
                        .unwrap()
                        .then(() => {
                            setVoteModalOpen(false);
                            setCompletedModalOpen(true);
                        });
                });
        },
        [dispatch, election]
    );

    const [actionAvailable, actionText, headerText] = React.useMemo(() => {
        if (!user) return [false, '', 'Необходимо\nавторизоваться'];
        if (!election || election.voted)
            return [false, 'Уже голосовали', 'Ваш голос\nучтен'];
        if (!election.is_active) return [false, '', 'Выборы скоро\nначнутся'];
        if (isStudent(user))
            if (
                election.faculty &&
                election.faculty.id !== user.group.faculty.id
            )
                return [false, '', 'Твой голос ждут\nна другом факультете'];
        return [true, 'К голосованию', 'Перейти \nк голосованию'];
    }, [election, user]);

    if (!election) return <h1>Загрузка</h1>;

    return (
        <>
            <div className="width-wrapper">
                <ActionCard
                    type={election.faculty?.name}
                    chips={ElectionChips(election)}
                    subHeader={`Студенческого совета ${
                        election.faculty?.name || 'СПбГУТ'
                    }`}
                >
                    {election.name}
                </ActionCard>
            </div>
            <Outlet />
            <div className="width-wrapper">
                <ActionCard
                    id="vote-card"
                    chips={
                        <Chip icon={<AlertIcon />}>
                            Голосование полностью конфиденциально
                        </Chip>
                    }
                    onClick={() => setVoteModalOpen(true)}
                    actionText={actionText}
                    type={election.faculty?.name}
                    showIcon
                    actionDisabled={!actionAvailable}
                >
                    {headerText}
                </ActionCard>
            </div>
            <div className="width-wrapper">
                <h3>Вопросы и ответы</h3>
                <QuestionBlock questions={questions} />
            </div>
            <VoteModal
                onSubmit={arg => submitVote(arg)}
                election={election}
                onCancel={() => setVoteModalOpen(false)}
                open={voteModalOpen}
            />
            <CompletedVoteModal
                open={completedModalOpen}
                onCancel={() => setCompletedModalOpen(false)}
            />
        </>
    );
}

export default React.memo(ElectionOutlet);
