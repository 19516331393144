import React from 'react';
import './LoginForm.scss';
import Input from 'components/ui/Input';
import Checkbox from 'components/ui/Checkbox';
import Button from 'components/ui/Button';
import { useAppDispatch } from 'store/store';
import {
    asyncSendStudentCode,
    asyncSendEmployeeCode,
    asyncSendEmail,
} from 'store/reducers/user/thunks';
import { SwitchValueProps } from 'components/ui/Switch/Switch';
import Switch from 'components/ui/Switch';
import { message } from 'antd';

interface StudentLoginFormProps {
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    code: string;
    setCode: React.Dispatch<React.SetStateAction<string>>;
    dispatch: ReturnType<typeof useAppDispatch>;
    codeError: boolean;
    setCodeError: React.Dispatch<React.SetStateAction<boolean>>;
}

function StudentLoginForm({
    email,
    setEmail,
    code,
    setCode,
    dispatch,
    codeError,
    setCodeError,
}: StudentLoginFormProps): React.ReactElement {
    const [disableCodeButton, setDisableCodeButton] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const onEmailSentSuccess = React.useCallback(() => {
        if (email.includes('@gmail.com'))
            messageApi.info(
                'Письмо отправлено. ' +
                    'Могут наблюдаться проблемы ' +
                    'с задержкой писем на почтах @gmail.com'
            );
        else {
            messageApi.info(`Письмо отправлено`);
        }
        setDisableCodeButton(true);
        setTimeout(() => {
            setDisableCodeButton(false);
        }, 3000);
    }, [email, messageApi]);

    const onEmailSentError = React.useCallback(() => {
        messageApi.warning('Нет пользователя с такой почтой');
        setEmailError(true);
    }, [messageApi]);

    const onSubmit = React.useCallback(() => {
        dispatch(asyncSendEmail(email))
            .unwrap()
            .then(onEmailSentSuccess)
            .catch(onEmailSentError);
    }, [dispatch, email, onEmailSentError, onEmailSentSuccess]);

    return (
        <>
            {contextHolder}
            <Input
                value={email}
                onChange={e => setEmail(e.target.value)}
                submitLabel="Отправить код"
                disabled={disableCodeButton}
                error={emailError}
                setError={setEmailError}
                onSubmit={onSubmit}
                placeholder="student@sut.ru"
                label="E-mail из личного кабинета СПбГУТ"
            />

            <Input
                value={code}
                onChange={e => setCode(e.target.value)}
                placeholder="ABC123"
                error={codeError}
                setError={setCodeError}
                label="Код подтверждения"
            />
        </>
    );
}

interface EmployeeLoginFormProps {
    code: string;
    setCode: React.Dispatch<React.SetStateAction<string>>;
    codeError: boolean;
    setCodeError: React.Dispatch<React.SetStateAction<boolean>>;
}

function EmployeeLoginForm({
    code,
    setCode,
    codeError,
    setCodeError,
}: EmployeeLoginFormProps): React.ReactElement {
    return (
        <Input
            value={code}
            onChange={e => setCode(e.target.value)}
            error={codeError}
            setError={setCodeError}
            placeholder="ABC123"
            label="Ваш персональный код"
        />
    );
}
interface LoginFormProps {
    onSuccess: () => void;
}

const switchValues: SwitchValueProps[] = [
    {
        key: 'student',
        name: 'Я студент',
    },
    {
        key: 'employee',
        name: 'Я сотрудник',
    },
];

function LoginForm({ onSuccess }: LoginFormProps): React.ReactElement {
    const dispatch = useAppDispatch();

    const [agreement, setAgreement] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [code, setCode] = React.useState('');
    const [codeError, setCodeError] = React.useState(false);
    const [switchValue, setSwitchValue] = React.useState(switchValues[0].key);
    const [messageApi, contextHolder] = message.useMessage();

    const sendCode = React.useCallback(() => {
        if (switchValue === 'employee') return asyncSendEmployeeCode(code);
        return asyncSendStudentCode(code);
    }, [code, switchValue]);

    return (
        <div className="login-form card-3">
            {contextHolder}
            <Switch
                values={switchValues}
                value={switchValue}
                onChange={value => setSwitchValue(value)}
                maxWidth="100%"
            />

            {switchValue === 'student' && (
                <StudentLoginForm
                    email={email}
                    setEmail={setEmail}
                    code={code}
                    setCode={setCode}
                    dispatch={dispatch}
                    codeError={codeError}
                    setCodeError={setCodeError}
                />
            )}

            {switchValue === 'employee' && (
                <EmployeeLoginForm
                    code={code}
                    setCode={setCode}
                    codeError={codeError}
                    setCodeError={setCodeError}
                />
            )}

            <Checkbox value={agreement} onChange={value => setAgreement(value)}>
                Я согласен(на) на обработку персональных данных
            </Checkbox>
            <Button
                disabled={!agreement || code.length === 0}
                onClick={() =>
                    dispatch(sendCode())
                        .unwrap()
                        .then(() => {
                            onSuccess();
                        })
                        .catch(() => {
                            setCodeError(true);
                            messageApi.warning('Неправильно введен код');
                        })
                }
                color="green"
                size="m"
                block
            >
                Войти
            </Button>
        </div>
    );
}

export default React.memo(LoginForm);
