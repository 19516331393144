import { FacultyName } from 'store/reducers/user/types';

export interface IFacultyStyle {
    image: string;
    background: string;
}

type IFacultyStyleMap = {
    [k in FacultyName]: IFacultyStyle;
};

export const FacultyStyleMap: IFacultyStyleMap = {};
